<template>
  <main>
    <Tab :numberOfTabs="tabs" :currentTab="tabNumber" borderColor="border-brand-black" @changeTab="changeTab" spread>
      <div v-if="tabNumber == 1" id="about" role="tabpanel" aria-labelledby="about-tab" class="grid mt-2">
        <talents />
      </div>
      <div v-if="tabNumber == 2" id="location" role="tabpanel" aria-labelledby="location-tab" class="grid mt-2">
        <talent-pool />
      </div>
    </Tab>
    <share-profile-dialog :open="showShareProfile" @on-close="showShareProfile = false" />
  </main>
</template>

<script setup>
import TalentDetails from '../components/TalentDetails.vue';
import TalentFilter from '../components/talent/Filter.vue';
import TamButton from '../components/TamButton.vue';
import Talents from '../components/talent/Talents.vue';
import TalentPool from '@/components/talent/TalentPool.vue';
import Tab from '@/components/Tab.vue';
import Loading from '../components/Loading.vue';
import Remove from '@/assets/icons/close-sm.svg?inline';
import { Filter, Category } from '@/icons';
import { ref, computed, onMounted, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { initFlowbite } from 'flowbite';
import ShareProfileDialog from '@/components/talent/ShareProfile.vue';

const tabNumber = ref(1);
const showShareProfile = ref(false);

const tabs = ref([
  {
    name: 'Talents',
  },
  {
    name: 'Talent Pool',
  },
]);

const changeTab = (value) => {
  tabNumber.value = value;
  // window.scrollTo(0, 50);
};

const showModal = ref(false);
const loading = ref(false);
const store = useStore();
const pageLoaded = ref(false);
const showFilter = ref(false);
const jobCategories = ref([]);
const enums = ref([]);
const level_of_experience = ref([
  { key: 'entry_level', value: 'Entry level' },
  { key: 'junior_level', value: 'Junior level' },
  { key: 'mid_level', value: 'Mid level' },
  { key: 'senior_level', value: 'Senior level' },
  { key: 'lead', value: 'Lead' },
  { key: 'executive', value: 'Executive' },
]);

const filterVars = ref([]);
const filter = ref({
  role_title: '',
  job_type: '',
  salary_currency: '',
  salary_min: '',
  salary_max: '',
  job_category: '',
  level_of_experience: '',
  search: '',
});

const filterKeys = ref({
  role_title: '',
  job_type: '',
  salary_currency: '',
  salary_min: '',
  salary_max: '',
  job_category: '',
  level_of_experience: '',
});

const closeModal = () => {
  showModal.value = false;
};

const capitalizeFirstLetter = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};

const setFilterVar = async (event, key, value = '') => {
  if (event.target.type != 'checkbox') {
    filterKeys.value[key] = event.target.value;
    filter.value[key] = value ? value : event.target.value;
  } else {
    filterKeys.value[key] = event.target.checked ? event.target.value : '';
    filter.value[key] = event.target.checked && value ? value : '';
  }

  const selected = Object.values(filter.value).filter((val) => val !== '') || [];
  filterVars.value = selected;
};

const removeFilterItem = async (index) => {
  Object.entries(filter.value).filter(([k, v]) => {
    if (filterVars.value[index] == v) {
      filter.value[k] = '';
      filterKeys.value[k] = '';
      return;
    }
  });

  filterVars.value.splice(index, 1);
  await getFilteredTalents();
};

const getFilteredTalents = async () => {
  const params = Object.entries(filterKeys.value).reduce((a, [k, v]) => (v == '' ? a : ((a[k] = v), a)), {});

  try {
    loading.value = true;
    await store.dispatch('global/getAllTalents', { params });
  } catch (error) {
  } finally {
    loading.value = false;
    showModal.value = false;
  }
};

const userDetails = computed(() => {
  return store.state.auth.userDetails;
});

onMounted(async () => {
  const jobCats = store.getters['global/getJobCategories'];
  jobCategories.value = !Array.isArray(jobCats) || jobCats?.length < 1 ? await store.dispatch('global/getJobCategories') : jobCats;

  const enumVals = store.getters['global/getEnumDetails'];
  enums.value = !Array.isArray(enumVals) || enumVals?.length < 1 ? await store.dispatch('global/getEnums') : enumVals;
  pageLoaded.value = true;
  initFlowbite();
});
</script>
