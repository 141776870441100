<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox" :fill="fill">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.52876 3.52851C5.78911 3.26816 6.21122 3.26816 6.47157 3.52851L10.4716 7.52851C10.7319 7.78886 10.7319 8.21097 10.4716 8.47132L6.47157 12.4713C6.21122 12.7317 5.78911 12.7317 5.52876 12.4713C5.26841 12.211 5.26841 11.7889 5.52876 11.5285L9.05735 7.99992L5.52876 4.47132C5.26841 4.21097 5.26841 3.78886 5.52876 3.52851Z"
            :fill="fillColor" />
    </svg>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    width: {
        type:  Number,
        default: 16
    },
    height: {
        type:  Number,
        default: 16
    },
    fill: {
        type: String,
        default: 'none'
    },
    fillColor: {
        type: String,
        default: '#1A1A1A'
    }
});

const viewBox = computed(() => `0 0 ${props.width} ${props.height}`);
</script>
