<template>
  <div class="flex justify-between pr-2">
    <div>
      <SubHeader :show="false" :page-title="`${talent?.name}`" :page-subtitle="`Talents > ${talent?.name}`" />
    </div>
    <div class="flex items-center">
      <a :href="linkedin_url" target="_blank" class="mr-2"><img src="@/assets/icons/linkedin.svg" alt="" /></a>
    </div>
  </div>

  <div class="md:grid grid-cols-2 gap-x-16 my-6">
    <div class="p-5 rounded-2xl bg-[#F6F6F9]">
      <h1 class="mb-5 text-[20px] font-bold">Personal Information</h1>
      <div class="relative">
        <div
          class="w-[100px] h-[100px] rounded-full bg-cover bg-no-repeat"
          :style="`background-image: url(${talent?.avatar ? talent?.avatar : affiliate})`"
        >
          <img src="" alt="" class="rounded-full" />
        </div>
        <div class="w-[32px] h-[32px] rounded-full bg-[#F3E0FF] flex items-center justify-center absolute left-[80px] bottom-[10px]">
          <img src="@/assets/icons/expand.svg" alt="" />
        </div>
      </div>

      <div class="flex items-center border-b py-5">
        <div class="w-1/3 text-[15px]">Talent Name</div>
        <div class="w-2/3 text-[15px] font-medium">
          {{ talent?.name }}
        </div>
      </div>
      <div class="flex items-center border-b py-5">
        <div class="w-1/3 text-[15px]">Field</div>
        <div class="w-2/3 text-[15px] font-medium">
          {{ talent?.professional_title || 'N/A' }}
        </div>
      </div>
      <div class="flex items-center border-b py-5">
        <div class="w-1/3 text-[15px]">Date Joined</div>
        <div class="w-2/3 text-[15px] font-medium">
          {{ talent?.date_joined ? moment(talent.date_joined).format('MMMM Do YYYY, h:mm:ss a') : '-' }}
        </div>
      </div>
      <div class="flex items-center border-b py-5">
        <div class="w-1/3 text-[15px]">Linkedin</div>
        <div class="w-2/3 text-[15px] font-medium">
          {{ linkedin_url }}
        </div>
      </div>
      <div class="flex items-center border-b py-5">
        <div class="w-1/3 text-[15px]">Available</div>
        <div class="w-2/3 text-[15px] font-medium">
          <span class="bg-[#089115]/[.10] p-2 px-4 text-[#089115] text-[16px] font-bold rounded-lg"> Available </span>
        </div>
      </div>
      <div class="flex items-center border-b py-5">
        <div class="w-1/3 text-[15px]">Resume</div>
        <div class="w-2/3 text-[15px] font-medium">
          <div v-if="talent?.resume" class="flex items-center">
            <img src="@/assets/icons/pdf.svg" class="mr-2" alt="" />
            <tam-button
              :href="talent?.resume"
              @click.prevent="resumeUrl(talent?.resume)"
              class="text-[#8F00E6] text-[15px] font-semibold"
              :label="resume_label"
            />
          </div>
          <div v-else>No resume uploaded</div>
        </div>
      </div>
      <div class="flex items-center border-b py-5">
        <div class="w-1/3 text-[15px]">Bio</div>
        <div class="w-2/3 text-[15px] font-medium">
          {{ talent?.bio || 'N/A' }}
        </div>
      </div>
    </div>
    <div class="p-5 rounded-2xl bg-[#F6F6F9]">
      <div class="flex items-center mb-5">
        <h1 class="text-[20px] font-bold mr-2">Job Applications</h1>
        <span class="bg-brand-primary px-2 py-1 rounded-lg text-white font-bold text-[12px]">{{ talent?.job_applications?.length }}</span>
      </div>

      <application-card v-for="(application, index) in talent?.job_applications" :application="application" :key="index" />

      <div class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-white dark:text-blue-400" role="alert" v-if="!talent?.job_applications?.length">
        <span class="font-medium">Talent</span> is yet to apply for any jobs yet!
      </div>
    </div>
  </div>

  <br />
  <br />
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment';
import { useStore } from 'vuex';
import { saveResumeUrl } from '@/utils/helper';
import ApplicationCard from '@/components/talent/ApplicationCard.vue';
import TamButton from '@/components/TamButton.vue';
import SubHeader from '@/components/AdminSubHeader.vue';
import affiliate from '@/assets/images/affiliate.png';

const route = useRoute();
const store = useStore();
const talent = ref({});

const linkedin_url = computed(() => {
  const platform = talent.value?.social_links?.find((item) => item.platform == 'linkedin');
  return platform?.url || 'N/A';
});

const resume_label = computed(() => `${talent.value?.name?.split(' ')?.join('_')}_CV`);

const resumeUrl = (url) => {
  saveResumeUrl(store, url);
};

const getDetail = async () => {
  talent.value = await store.dispatch('global/getSingleTalent', route.params.id);
};

onMounted(async () => {
  try {
    getDetail();
  } catch (error) {}
});
</script>

<style scoped lang="scss"></style>
