<template>
  <main>
    <loader v-if="!pageLoaded || loading" />
    <div v-if="pageLoaded">
      <div class="bg-white border border-[#F0F0F0] rounded-xl p-6 mb-7">
        <h2 class="text-[18px] text-brand-black leading-[150%] font-bold mb-4">
          Find over 21,000 qualified talents <br />
          to hire for your company
        </h2>
        <div class="flex space-x-3 items-center">
          <div class="relative w-full">
            <input
              type="search"
              class="block w-full px-4 py-3 pr-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50"
              placeholder="Search talents"
              v-model="filterKeys.search"
              @change="getAllTalents"
              required
            />

            <button class="absolute right-4 top-3 z-10">
              <img src="../../assets/icons/search.svg" alt="" />
            </button>
          </div>

          <tam-button
            class="bg-brand-black text-white rounded-xl text-[14px] hover:bg-brand-black"
            label="Browse"
            color="secondary"
            :icon="Cross"
            @click="showFilter = true"
          />

          <!-- <div class="relative mb7">
            <button
              id="dropdownDefaultButton"
              data-dropdown-toggle="dropdown"
              aria-controls="drawer-navigation"
              class="border border-light-grey rounded-[10px] font-medium text-sm text-brand-black bg-white p-3 px-6 text-center inline-flex items-center"
              type="button"
              @click="showActions = !showActions"
            >
              Action
              <svg class="w-2.5 h-2.5 ml-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
              </svg>
            </button>
  
            <div
              id="dropdown"
              class="z-10 absolute right-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
              v-if="showActions"
            >
              <ul class="p-2.5 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                <li>
                  <p @click.prevent="showProfilesShareDialog = true" class="block px-2 py-2.5 hover:bg-[#FEFAF1] hover:rounded-lg cursor-pointer">
                    Share Profile
                  </p>
                </li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>

      <div class="bg-white border border-[#F0F0F0] rounded-xl p-6 mb-7">
        <h2 class="text-[18px] text-brand-black leading-[150%] font-bold mb-1">Explore by <span class="text-brand-original">Categories</span></h2>
        <p class="text-gray-400 text-[15px] leading-[24px]">Find over 21,000 qualified talents to hire for your company</p>

        <div class="flex gap-4 items-center flex-wrap mt-4">
          <button
            value=""
            @click="
              setFilterVar($event, 'job_category', '');
              getAllTalents();
            "
            :class="[
              'inline-block px-6 py-2 text-[15px] font-manrope capitalize',
              !filterKeys['job_category']
                ? 'border border-brand-black rounded-xl text-brand-black font-bold'
                : 'rounded-xl text-gray-600 border border-gray-300',
            ]"
          >
            All
          </button>
          <button
            :value="item.key"
            @click="
              setFilterVar($event, 'job_category', item.value);
              getAllTalents();
            "
            :class="[
              'inline-block px-6 py-2 text-[15px] font-manrope capitalize',
              filterKeys['job_category'] == item.key
                ? 'border border-brand-black rounded-xl text-brand-black font-bold'
                : 'rounded-xl text-gray-500 border border-gray-300',
            ]"
            v-for="item in jobCategories"
            :key="item.key"
          >
            {{ item.value }}
          </button>
        </div>
      </div>

      <h1 class="text-brand-original font-bold text-[16px] leading-[150%] capitalize mb-3">
        {{ filter.job_category || 'All' }}
      </h1>
      <div class="table-responsive">
        <table class="w-full text-sm text-left text-gray-500 p-4">
          <thead class="text-xs text-white font-bold bg-brand-black">
            <tr>
              <th scope="col" class="px-6 py-3 whitespace-nowrap text-left" v-for="item in tableDetails" :key="item">
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="talent in talents" :key="talent.uuid" class="bg-white border-b hover:bg-gray-50">
              <td v-if="checkbox" class="w-4 p-4">
                <div class="flex items-center">
                  <input
                    id="checkbox-table-1"
                    type="checkbox"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label for="checkbox-table-1" class="sr-only">checkbox</label>
                </div>
              </td>
              <td scope="ro" class="px-4 py-[18px] font-medium whitespace-nowrap">
                <div class="flex items-center cursor-pointer" @click.prevent="updateGeneralDialog(item)">
                  <img :src="talent.avatar" alt="image" class="w-6 h-6 rounded-full mr-3" />
                  <div class="flex flex-col text-xs text-[#1A1A1A]">
                    {{ talent.name }}
                    <small class="text-[10px] text-[#8F8F8F]">{{ talent.email }}</small>
                  </div>
                </div>
              </td>
              <td class="px-6 py-4 text-gray-600 text-xs font-extralight inline-flex items-center">
                {{ talent?.role_title || 'N/A' }}
              </td>
              <td class="px-6 py-4">
                <span
                  :class="[
                    'px-6 py-2 text-[11px] rounded-xl',
                    item?.availability_status ? 'text-[#00AD73] bg-[#E5FFF6]' : 'text-[#CB0156] bg-[#FFF0F6]',
                  ]"
                  >{{ item?.availability_status ? 'Available' : 'Not Available' }}</span
                >
              </td>
              <td class="px-4 py-[18px] font-normal text-brand-black">
                {{ moment(talent.date_joined).format('D MMM YYYY') }}
              </td>
              <td class="px-6 py-4 text-right">
                <Menu as="div" class="relative inline-block text-left">
                  <div>
                    <MenuButton class="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-gray-700">
                      <More />
                    </MenuButton>
                  </div>

                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute z-10 origin-top right-0 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[160px]"
                    >
                      <div class="p-1 py-2">
                        <MenuItem>
                          <a href="#" @click="shareProfile(talent)" class="inline-flex items-center px-4 py-2 text-brand-black cursor-pointer">
                            <span> Share Profile</span>
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <router-link :to="`/talentpool/${talent?.uuid}`" class="inline-flex px-4 py-2 text-brand-black">
                            <span>View Talent</span>
                          </router-link>
                        </MenuItem>
                        <MenuItem>
                          <a href="#" @click.prevent="emailTalent(talent)" class="inline-flex px-4 py-2 text-brand-black">
                            <span>Email Talent</span>
                          </a>
                        </MenuItem>
                      </div>
                    </MenuItems>
                  </transition>
                </Menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Paginate
      :from="paginateContent.meta.from"
      :to="paginateContent.meta.to"
      :total="paginateContent.meta.total"
      :current="paginateContent.meta.current_page"
      :next="paginateContent.links.next"
      :prev="paginateContent.links.prev"
      :pages="paginateContent.meta.links"
      @move="doPaginate"
    />
    <send-talents-mail-dialog :open="showSendTalentMail" @on-close="showSendTalentMail = false" :talentDetails="talentDetails" />
    <share-profiles-dialog :open="showProfilesShareDialog" @on-close="showProfilesShareDialog = false" :talents="talents" />

    <share-profile-dialog :open="showProfileShareDialog" @on-close="showProfileShareDialog = false" :appDetails="talentDetails" />

    <talent-filter
      :open="showFilter"
      :enums="enums"
      :job-categories="jobCategories"
      :experience="level_of_experience"
      :filter="filter"
      :filterVars="filterVars"
      @update="setFilterVar"
      @on-apply="getAllTalents"
      @on-close="closeFilter"
      @on-clear="clearFilter"
    />
  </main>
</template>

<script setup>
import TamButton from '../TamButton.vue';
import { FilterLine, Cross, Search, More } from '@/icons';
import moment from 'moment';
import Loader from '@/components/LoadingOverlay.vue';
import { ref, onMounted, computed } from 'vue';
import { initFlowbite } from 'flowbite';
import Paginate from '@/components/Paginate.vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import SendTalentsMailDialog from '@/components/talent/SendTalentsMail.vue';
import ShareProfilesDialog from './ShareProfile.vue';
import ShareProfileDialog from '@/components/applications/ShareProfile';
import TalentFilter from './Filter.vue';

const store = useStore();
const router = useRouter();
const tableDetails = ref([' ', 'Name', 'Field', 'Availability', 'Date Joined', 'Action']);
const pageLoaded = ref(false);
const loading = ref(false);
const showFilter = ref(false);
const showActions = ref(false);
const showSendTalentMail = ref(false);
const checkbox = ref(true);
const showProfileShareDialog = ref(false);
const showProfilesShareDialog = ref(false);
const talentDetails = ref(null);
const level_of_experience = ref([
  { key: 'entry_level', value: 'Entry level' },
  { key: 'junior_level', value: 'Junior level' },
  { key: 'mid_level', value: 'Mid level' },
  { key: 'senior_level', value: 'Senior level' },
  { key: 'lead', value: 'Lead' },
  { key: 'executive', value: 'Executive' },
]);

const filterVars = ref([]);
const filter = ref({
  role_title: '',
  job_type: '',
  salary_currency: '',
  salary_min: '',
  salary_max: '',
  job_category: '',
  level_of_experience: '',
  search: '',
});

const filterKeys = ref({
  role_title: '',
  job_type: '',
  salary_currency: '',
  salary_min: '',
  salary_max: '',
  job_category: '',
  level_of_experience: '',
  search: '',
});

const talents = computed(() => {
  return store.getters['global/getAllTalents'];
});

const jobCategories = computed(() => {
  return store.getters['global/getJobCategories'];
});

const enums = computed(() => {
  return store.getters['global/getEnumDetails'];
});

// Add new job, if talent is empty
const openJob = () => {
  router.push('/jobs');
};

const doPaginate = async ({ params }) => {
  await store.dispatch('global/getAllTalents', { params });
};

const paginateContent = computed(() => {
  return store.getters['global/getTalentsPayload'];
});

const closeFilter = () => {
  showFilter.value = false;
};

const setFilterVar = async (event, key, value = '') => {
  if (event.target.type != 'checkbox') {
    filterKeys.value[key] = event.target.value;
    filter.value[key] = value ? value : event.target.value;
  } else {
    filterKeys.value[key] = event.target.checked ? event.target.value : '';
    filter.value[key] = event.target.checked && value ? value : '';
  }

  const selected = Object.values(filter.value).filter((val) => val !== '') || [];
  filterVars.value = selected;
};

const removeFilterItem = async (index) => {
  Object.entries(filter.value).filter(([k, v]) => {
    if (filterVars.value[index] == v) {
      filter.value[k] = '';
      filterKeys.value[k] = '';
      return;
    }
  });

  filterVars.value.splice(index, 1);
  await getFilteredTalents();
};

const clearFilter = async (index) => {
  Object.entries(filter.value).filter(([k, v]) => {
    filter.value[k] = '';
    filterKeys.value[k] = '';
  });

  filterVars.value = [];
  await getAllTalents();
};

const getAllTalents = async () => {
  const params = Object.entries(filterKeys.value).reduce((a, [k, v]) => (v == '' ? a : ((a[k] = v), a)), {});

  try {
    loading.value = true;
    await store.dispatch('global/getAllTalents', { params });
  } catch (error) {
  } finally {
    showFilter.value = false;
    loading.value = false;
  }
};

const shareProfile = (talent) => {
  talentDetails.value = talent;
  showProfileShareDialog.value = true;
};

const emailTalent = (talent) => {
  talentDetails.value = talent;
  showSendTalentMail.value = true;
};

onMounted(async () => {
  await Promise.all([getAllTalents(), store.dispatch('global/getJobCategories'), store.dispatch('global/getEnums')]);
  pageLoaded.value = true;
  initFlowbite();
});
</script>
