<template>
    <section class="flex items-center justify-between my-6 font-[ManropeMedium]" v-if="from">
        <nav aria-label="navigation">
            <ul class="inline-flex items-center -space-x-px">
                <li>
                    <a @click="selectLink(prevPage)"
                        class="block px-3 py-2 ml-0 leading-tight text-[#889088] bg-white rounded-l-lg hover:bg-[#687588] hover:text-white">
                        <span class="sr-only">Previous</span>
                        <ChevronLeftIcon />
                    </a>
                </li>

                <PaginateLink v-for="(link, idx) in links.slice(1, -1)" :key="idx" @select-link="selectLink" :index="idx + 1"
                    :totalLinks="links.slice(1, -1).length" :link="link" :currentPage="currentPage" />

                <li>
                    <a @click="selectLink(nextPage)"
                        class="block px-3 py-2 ml-0 leading-tight text-[#889088] bg-white rounded-r-lg hover:bg-[#F8F8F8] hover:text-white">
                        <span class="sr-only">Next</span>
                        <ChevronRightIcon />
                    </a>
                </li>
            </ul>
        </nav>
        <div class="flex flex-row items-center">
            <!-- Help text -->
            <span class="text-xs font-extralight text-brand-black flex flex-row justify-between font-[ManropeMedium]">
                Showing: <span class="flex mr-1">{{ from }}</span> - <span class="flex mx-1">{{ to }}</span> of <span
                    class="flex ml-1">{{ total }} entries &nbsp; Show</span>
            </span>
            <span class="flex">
                <select class="select text-xs border-0" @change="selectPage">
                    <option v-for="(item, idx) in pagesToDisplay" :key="idx" :value="item.key" :v-model="currentPage">{{
                        item.value }}</option>
                </select>
            </span>
        </div>
    </section>
</template>
  
<script setup>
import { computed } from 'vue';
import { ChevronRightIcon, ChevronLeftIcon } from '@/components/Icons'
import PaginateLink from './PaginateLink.vue';

const props = defineProps({
    from: {
        type: Number
    },
    to: {
        type: Number
    },
    total: {
        type: Number
    },
    links: {
        type: Array
    },
    currentPage: {
        type: Number,
        default: 1
    },
    nextPage: {
        type: String
    },
    prevPage: {
        type: String
    },
    navigations: {
        type: Object
    },
    perPage: {
        type: Number,
        default: 10
    }
});

const emit = defineEmits(['move']);

const paginate = (url) => {
    if (!url) return;
    //const link = new URL(url);
    emit('move', { params: createParams(url) });
};

const createParams = (url) => {
    const urlSegments = url.split('?');
    let params = {};
    if (urlSegments[1] === undefined) {
        params = {};
    } else {
        params = urlSegments[1]
            .split('&')
            .reduce((pv, cv) => {
                const param = {};
                param[`${cv.split('=')[0]}`] = cv.split('=')[1];
                pv = { ...pv, ...param };
                return pv;
            }, {});
    }
    return params;
};

const pagesToDisplay = computed(() => {
    const max_page = Math.ceil(props.total / props.perPage);
    const arr = [];
    for (let index = 1; index <= max_page; index++) {
        arr.push({ key: index, value: index });
    }
    return arr;
});

const selectLink = async (url) => {
    if (!url) return;
    paginate(await createQueryLink(url, { target: 'page', value: url.split('=')[1] || 1 }));
}

const selectPage = async (ev) => {
    ev.preventDefault();
    const active_link = props.links.filter((li) => li.active === true)[0];
    paginate(await createQueryLink(active_link.url, { target: 'page', value: ev.target.value }));
}

const createQueryLink = async (url, { target, value }) => {
    const urlSegments = url.split('?');
    let params = '';
    if (urlSegments[1] === undefined) {
        params = `?${target}=${value}`;
    } else {
        params = urlSegments[1]
            .split('&')
            .filter(param => param.split('=')[0] !== target)
            .join('&');
        params = `?${params}&${target}=${value}`;
    }
    if (target !== 'per_page') {
        params = `${params}&per_page=${props.perPage}`
    }

    return `${urlSegments[0]}${params}`;
};

</script>
  