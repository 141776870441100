<template>
    <li>
        <a v-if="showLink" @click="selectLink(link.url)"
        :class="link.active ? 'bg-[#F8F8F8] rounded-lg text-brand-black' : 'bg-white text-[#13201380]/50'"
        class="px-3 py-2 mx-1 leading-tight text-base hover:rounded-lg hover:bg-[#F0386A]/50 hover:text-white cursor-pointer">{{
            link.label }}</a>
        <span v-else>.</span>
    </li>
</template>
<script setup>
import { computed } from 'vue';
const props = defineProps({
    link: {
        type: Object,
        required: true
    },
    index: {
        type: Number,
        required: true
    },
    totalLinks: {
        type: Number,
        required: true,
    },
    currentPage: {
        type: Number,
        required: true,
    }
});

const emits = defineEmits(['select-link']);

const selectLink = (url) => emits('select-link', url);

const showLink = computed(() => {
    if (props.totalLinks < 4 || props.index === 1) return true; // links are not more than 3 or index is 1, show all
    // links are more than 3
    if (props.currentPage === 1) {
        if (props.index < 4 || props.index === props.totalLinks ) return true; // showing the first page and link index is 1 to 3 or the last
    } else {
        if (props.index == props.currentPage || props.index === props.totalLinks ) return true; // link index is current page or the last
    }
    return false; //in between link
})
</script>