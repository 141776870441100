<template>
  <main>
    <div class="mt-8 px-2 xl:px-0 xl:mt-0 mb-8">
      <div class="flex justify-between items-center">
        <div>
          <p class="text-2xl text-[#12080F] font-bold" v-html="currentRouteName">
          </p>
          <p class="font-normal text-xs xl:text-base text-[#12080F] text-opacity-70" v-html="currentRouteSubText"></p>
        </div>
        <div v-if="show" class="xl:basis-2/5 basis-0">
          <div class="flex items-center">
            <div class="grow hidden xl:block">
              <form>
                <div class="relative">
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                  >
                    <Search />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    class="block w-full py-2 px- text-sm placeholder:text-[#95a3a6] placeholder:font-normal placeholder:text-sm bg-[#f3f2f3] rounded-lg border border-[#e5e4e5] focus:border-ring-0"
                    placeholder="Type anything"
                    required
                  />
                </div>
              </form>
            </div>
            <button
              class="flex justify-between items-center font-normal text-sm ml-4 py-2 px-2 xl:px-6 border border-brand-primary rounded-md text-brand-primary xl:space-x-2"
            >
              <Filter class="hidden xl:block text-brand-primary" />
              <span class="hidden xl:block">Filters</span>
              <Filter class="block xl:hidden text-brand-primary" />
            </button>
          </div>
        </div>
        <div v-if="button">
          <slot name="button"></slot>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import Filter from "@/assets/icons/filter.svg?inline";
import Search from "@/assets/icons/search.svg?inline";

const props = defineProps({
  show: { type: Boolean, default: true },
  pageTitle: { type: String, default: "" },
  pageSubtitle: { type: String, default: "" },
  button: { type: Boolean, default: false },
});

const route = useRoute();
const currentRouteName = computed(() => {
  return route.meta.title || props.pageTitle;
});
const currentRouteSubText = computed(() => {
  return route.meta.text || props.pageSubtitle;
});
</script>
