<template>
  <Dialog v-show="open && talents.length" class="backdrop-blur-sm" :extend-class="['md:max-w-2xl mb-[2rem] mt-[15rem]']">
    <div class="my-6 bg-white rounded- w-full">
      <div class="flex justify-between px-6">
        <div class="w-full">
          <div class="flex justify-between items-start">
            <div class="mb-4">
              <h1 class="text-[20px] font-semibold leading-[130%] text-brand-black mb-2">Share Profiles</h1>
              <div class="flex space-x-3 items-center">
                <img src="../../assets/icons/infoline.svg" alt="" />
                <small class="text-light-gray">You are sharing profiles of multiple people</small>
              </div>
            </div>

            <img src="../../assets/icons/closeline.svg" class="cursor-pointer" alt="" @click="closeModal" />
          </div>

          <div class="overflow-y-auto h-[85vh]">
            <div class="pt-4 mb-6 border border-[#F0F0F0] rounded-lg">
              <div class="w-full border-b border-[#F0F0F0] px-6 pb-3">
                <div class="flex items-end space-x-3">
                  <span>To</span>
                  <input
                    type="text"
                    class="border-0 outline-0 focus:outline-0 focus:border-0 py-0 px-0 focus:ring-0 placeholder:bg-light-gray-100 text-sm grow"
                    placeholder="Type an email address..."
                    v-model="details.email"
                  />
                </div>
              </div>
              
              <div class="w-full border-b border-[#F0F0F0] px-6 py-3 pb-4">
                <div class="flex items-center space-x-3">
                  <span>Profiles:</span>
                  <Multiselect
                    v-model="details.candidates"
                    mode="multiple"
                    :object="true"
                    :searchable="true"
                    :close-on-select="false"
                    :options="filteredTalents"
                    class="border-0 outline-0 focus:outline-0 focus:border-0 py-0 px-0 focus:ring-0 placeholder:bg-light-gray-100 text-sm grow"
                  />
                </div>
              </div>
              <div class="w-full border-b border-[#F0F0F0] px-6 py-3 pb-4">
                <div class="flex items-end space-x-3">
                  <span>Subject</span>
                  <input
                    type="text"
                    class="border-0 outline-0 focus:outline-0 focus:border-0 py-0 px-0 focus:ring-0 grow"
                    placeholder="Invitation to Job Application"
                    v-model="details.subject"
                  />
                </div>
              </div>
              <div class="w-full border-b border-[#F0F0F0]">
                <QuillEditor v-model:content="details.content" contentType="html" class="bg-white" theme="snow" toolbar="minimal" />
              </div>
            </div>

            <div class="mb-3">
              <div class="flex justify-end space-x-4">
                <button @click="closeModal" class="bg-white p-3 text-brand-black rounded-lg border border-brand-black px-10 text-[14px]">
                  Cancel
                </button>
                <button class="bg-brand-black p-3 text-white rounded-lg px-10 text-[14px]" @click="shareProfile">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import Dialog from '@/components/Dialog.vue';
import { alert } from '@/utils/helper';
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import Multiselect from '@vueform/multiselect';

const store = useStore();
const details = ref({
  email: '',
  subject: '',
  candidates: [],
  content: `Hi Cecilia,

Thank you very much for applying for the Engineering Manager position at Pixel Office.

Please be informed that we have received your application. Our hiring team is currently reviewing all applications. If you are among qualified candidates, you will receive an email notifying you of the next step soon.

Thanks again for your interest in working at our company.

Best regards,

Pixel Office`,
});

const props = defineProps({
  open: { type: Boolean },
  talents: { type: Array, default: [] },
});

const emit = defineEmits(['onClose']);

const closeModal = () => {
  emit('onClose');
};

const filteredTalents = computed(() => {
  const filtered = props.talents?.map((item) => {
    const { name, uuid, email, avatar } = item;
    return { name, uuid, email, avatar, label: email, value: uuid };
  });

  return filtered;
});

const shareProfile = async () => {
  try {
    const data = {
      ...details.value,
      candidates: details.value.candidates.map((item) => (item.uuid)),
    };
    
    const res = await store.dispatch('global/shareTalentProfile', data);
    if (res.status === true) {
      alert(res.message, 'success');
      closeModal();
    }
  } catch (error) {
    alert(error.response.data.message, 'error');
  }
};

onMounted(() => {
  // console.log(props.talentDetails, "SHARE MODAL >>>>>>><<<<<<<<");
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
