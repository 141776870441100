<template>
  <main>
    <loader v-if="!pageLoaded || loading" />
    <div v-if="pageLoaded">
      <div class="bg-[#FFFBE1] border border-[#DEDEDE] py-5 px-6 flex items-center justify-between my-6">
        <div>
          <p class="text-[#1A1A1A] text-base font-semibold">
            Access your talent faster <br />
            and easier with a talent pool
          </p>
        </div>
        <div>
          <tam-button class="bg-black text-white hover:bg-black py-4 rounded-[10px]" to="/subscriptions" label="Pay for Talent Pool" />
        </div>
      </div>

      <div>
        <table class="w-full text-sm text-left text-gray-500 p-4">
          <thead class="text-xs text-white font-bold bg-brand-black">
            <tr>
              <th scope="col" class="px-6 py-3 whitespace-nowrap" v-for="item in tableDetails" :key="item">
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- v-for="item in allApplication" :key="item.uuid" -->
            <tr v-if="talents.length < 1">
              <td colspan="4">
                <div class="flex justify-center flex-col items-center mt-40">
                  <p class="font-FoundersMedium text-black text-2xl mb-4">Talent pool is Premium</p>
                  <tam-button
                    class="bg-brand-black text-white rounded-xl text-[14px] hover:bg-brand-black"
                    color="secondary"
                    label="Pay for Talent Pool"
                    :icon="Cross"
                    @click="openJob"
                  />
                </div>
              </td>
            </tr>
            <tr v-else class="bg-white border-b hoverbg-gray-300" v-for="talent in talents" :key="talent.uuid">
              <td scope="ro" class="px-4 py-[18px] font-medium whitespace-nowrap">
                <div class="flex items-center cursor-pointer" @click.prevent="updateGeneralDialog(item)">
                  <img :src="talent.avatar" alt="image" class="w-6 h-6 rounded-full mr-3" />
                  <div class="flex flex-col text-xs text-[#1A1A1A]">
                    {{ talent.name }}
                    <small class="text-[10px] text-[#8F8F8F]">{{ talent.email }}</small>
                  </div>
                </div>
              </td>
              <td class="px-4 py-[18px]">
                <p class="text-brand-black font-light py-2">{{ talent.role_title || 'Not Available' }}</p>
              </td>

              <td class="px-4 py-[18px]">
                <div
                  class="rounded-full whitespacenowrap h-[35px] w-8/12"
                  :class="talent.availability_status === 'open' ? 'bg-[#EBFFF8]' : 'bg-[#FFF0F6]'"
                >
                  <p class="font-normal text-center py-2" :class="talent.availability_status === 'open' ? 'text-[#00AD73]' : 'text-[#CB0156]'">
                    {{ talent.availability_status === 'open' ? 'Available' : 'Not Available' }}
                  </p>
                </div>
              </td>
              <td class="px-4 py-[18px] font-normal text-brand-black">
                {{ moment(talent.created_at).format('D MMM YYYY') }}
              </td>
              <td class="px-4 py-[18px]">
                <button
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="status"
                  aria-controls="drawer-navigation"
                  class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  type="button"
                >
                  <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
                    <path
                      d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                    />
                  </svg>
                </button>

                <!-- Dropdown menu -->
                <div id="status" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                  <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                    <li>
                      <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                    </li>
                    <li>
                      <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                    </li>
                    <li>
                      <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                    </li>
                  </ul>
                </div>
                <!-- <div class="flex justify-end"> -->
                <!-- <More /> -->

                <!-- </div> -->

                <!-- <Menu as="div" class="relative inline-block text-left">
                <div>
                  <MenuButton class="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-gray-700">
                    <More fill="black" />
                  </MenuButton>
                </div>

                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems class="relatie z-10 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem>
                      <a href="#" @click.prevent="updateGeneralDialog(item)" class="block px-4 py-2 hover:bg-brand-hover hover:rounded-md text-black"
                        >Manage Application</a
                      >
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script setup>
import TamButton from '../TamButton.vue';
import { FilterLine, Cross, Search, More } from '@/icons';
import moment from 'moment';
import Loader from '@/components/LoadingOverlay.vue';
import { ref, onMounted, computed } from 'vue';
import { initFlowbite } from 'flowbite';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

const store = useStore();
const router = useRouter();
const tableDetails = ref(['Pipeline Name', 'Talents', 'Date Created', 'Action']);
const pageLoaded = ref(false);
const loading = ref(false);

// change to talent pool
const talents = computed(() => {
  return [];
  // return store.getters['global/getAllTalents'];
});

// Add new job, if talent is empty
const openJob = () => {
  router.push('/jobs');
};

onMounted(async () => {
  await store.dispatch('global/getAllTalents');
  pageLoaded.value = true;
  initFlowbite();
});
</script>
