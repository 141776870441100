<template>
  <div v-if="open" class="fixed inset-0 z-40">
    <!-- OVERLAY -->
    <div class="fixed inset-0 bg-gray-900 opacity-70 backdrop-blur-3xl"></div>
    <!-- FILTER -->
    <div class="fixed top-0 bottom-0 right-0 bg-white max-w-xl w-9/12 md:w-1/2 p-5">
      <div class="flex justify-between items-center mb-5">
        <h2 class="font-semibold text-[22px]">Talents Filter</h2>
        <TamButton @click="closeModal" color="white" class="bg-white hover:bg-white" :icon="CloseOutline" />
      </div>

      <div class="overflow-y-auto h-[85vh] custom-scroll">
        <div class="px-2">
          <div class="mb-6">
            <label for="" class="text-sm font-medium text-brand-black mb-3 inline-block">Role title</label>
            <input
              type="text"
              :value="filter.search"
              @change="updateFilter($event, 'search')"
              class="block w-full px-4 py-3 pr-10 text-sm text-gray-900 bborder border-light-grey rounded-md bg-white"
              placeholder="Enter role title"
              required
            />
          </div>

          <div class="mb-6">
            <label for="" class="text-sm font-medium text-brand-black mb-3 inline-block">Job Category</label>
            <select
              class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
              required
              @change="updateFilter($event, 'job_category')"
            >
              <option value="">Select category</option>
              <option
                v-for="item in jobCategories"
                :key="item.key"
                :value="item.key"
                :data-value="item.value"
                :selected="filterVars.includes(item.value)"
              >
                {{ item.value }}
              </option>
            </select>
          </div>

          <div class="mb-6">
            <label for="" class="text-sm font-medium text-brand-black mb-3 inline-block">Salary Currency</label>
            <select
              class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
              required
              @change="updateFilter($event, 'salary_currency')"
            >
              <option value="">Select currency</option>
              <option
                v-for="item in enums?.currencies"
                :key="item.key"
                :value="item.key"
                :data-value="item.value"
                :selected="filterVars.includes(item.value)"
              >
                {{ item.value }}
              </option>
            </select>
          </div>

          <div class="flex space-x-4">
            <div class="mb-6 w-1/2">
              <label for="" class="text-sm font-medium text-brand-black mb-3 inline-block">Salary (Minimum)</label>
              <input
                type="text"
                placeholder="Minimum Salary"
                :value="filter.salary_min"
                @change="updateFilter($event, 'salary_min')"
                class="block w-full px-4 py-3 pr-10 text-sm text-gray-900 bborder border-light-grey rounded-md bg-white"
                required
              />
            </div>

            <div class="mb-6 w-1/2">
              <label for="" class="text-sm font-medium text-brand-black mb-3 inline-block">Job Status</label>
              <input
                type="text"
                placeholder="Maximum Salary"
                :value="filter.salary_max"
                @change="updateFilter($event, 'salary_max')"
                class="block w-full px-4 py-3 pr-10 text-sm text-gray-900 bborder border-light-grey rounded-md bg-white"
                required
              />
            </div>
          </div>

          <div class="grid grid-cols-2 gap-6 mb-6">
            <div>
              <div class="mb-8">
                <div class="flex items-center justify-between mb-3">
                  <div class="flex items-center space-x-2">
                    <label class="inline-block text-[14px] text-brand-black">Job Type</label>
                  </div>
                  <img src="../../assets/icons/chevron-down.svg" alt="" />
                </div>

                <div class="bg-light-gray-100 h-auto max-h-[240px] overflow-y-auto custom-scroll p-4 rounded-xl">
                  <div class="flex items-center mb-6 space-x-3" v-for="item in enums?.job_types" :key="item.key">
                    <input
                      :id="`checkbox-item-${item.key}`"
                      type="checkbox"
                      :value="item.key"
                      :checked="filterVars.includes(item.value)"
                      @change="updateFilter($event, 'job_type', item.value)"
                      class="w-4 h-4 text-brand-black bg-white border-2 border-brand-black focus:ring-brand-black focus:ring-0 outline-none focus:outline-none"
                    />
                    <label for="green-radio" class="text-sm font-medium text-brand-black capitalize">{{ item.value }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="mb-6">
                <div class="flex items-center justify-between mb-3">
                  <div class="flex items-center space-x-2">
                    <label class="inline-block text-[14px] text-brand-black">Level of Experience</label>
                  </div>
                  <img src="../../assets/icons/chevron-down.svg" alt="" />
                </div>

                <div class="bg-light-gray-100 h-auto p-4 rounded-xl">
                  <div class="flex items-center mb-6 space-x-3" v-for="item in experience" :key="item.key">
                    <input
                      :id="`checkbox-item-${item.key}`"
                      type="checkbox"
                      :value="item.key"
                      :checked="filterVars.includes(item.value)"
                      @change="updateFilter($event, 'level_of_experience', item.value)"
                      class="w-4 h-4 text-brand-black bg-white border-2 border-brand-black focus:ring-brand-black focus:ring-0 outline-none focus:outline-none"
                    />
                    <label for="green-radio" class="text-sm font-medium text-brand-black capitalize">{{ item.value }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-2 flex space-x-6 items-center mb-3 mt-8">
            <button
              type="button"
              @click.prevent="clear"
              class="bg-white text-[#FE207E] font-medium p-4 py-3 rounded-lg w-1/2 border border-brand-black"
            >
              Clear all
            </button>
            <button @click="apply" class="bg-brand-black text-white p-4 py-3 rounded-lg w-1/2">Apply filter</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CloseOutline } from '../../icons';
import TamButton from '@/components/TamButton.vue';

const props = defineProps({
  filter: { type: Object, default: {} },
  filterVars: { type: Object, default: [] },
  enums: { type: Array, default: [] },
  jobCategories: { type: Array, default: [] },
  experience: { type: Array, default: [] },
  open: { type: Boolean },
});

const emit = defineEmits(['onClose', 'onClear', 'update', 'onApply']);
const closeModal = () => {
  emit('onClose');
};

const clear = () => {
  emit('onClear');
};

const apply = () => {
  emit('onApply');
};

const updateFilter = (event, key, value = '') => {
  let dataVal = '';
  if (event.target.type == 'select-one') {
    dataVal = event.target.options[event.target.options.selectedIndex].getAttribute('data-value');
  }

  emit('update', event, key, dataVal ? dataVal : value);
};
</script>
