<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox" :fill="fill">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10.4716 3.52851C10.7319 3.78886 10.7319 4.21097 10.4716 4.47132L6.94297 7.99992L10.4716 11.5285C10.7319 11.7889 10.7319 12.211 10.4716 12.4713C10.2112 12.7317 9.78911 12.7317 9.52876 12.4713L5.52876 8.47132C5.26841 8.21097 5.26841 7.78886 5.52876 7.52851L9.52876 3.52851C9.78911 3.26816 10.2112 3.26816 10.4716 3.52851Z"
            :fill="fillColor" />
    </svg>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    width: {
        type: Number,
        default: 16
    },
    height: {
        type: Number,
        default: 16
    },
    fill: {
        type: String,
        default: 'none'
    },
    fillColor: {
        type: String,
        default: '#1A1A1A'
    }
});

const viewBox = computed(() => `0 0 ${props.width} ${props.height}`);
</script>
